/**
 * This component is mainly a HOC that adds some global styles and render children as if
 * nothing happened
 */
import "normalize.css";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const fontTitle = "./fonts/title.woff";
const fontTextBold = "./fonts/text-bold.woff2";
/**
 * The following abbreviations are used accross stylesheets in this project
 - w: width
 - h: height
 - p: padding
 - m: margin
 - ver: vertical
 - hor: horizontal
 */

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Merriweather';
    src: url('/fonts/Merriweather-Regular.woff2') format('woff2'),
        url('fonts/Merriweather-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Text";
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url("/fonts/text.woff2") format("woff2"),
    url("/fonts/text.woff") format("woff");
}

@font-face {
  font-family: "Text";
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  src: url("/fonts/text-bold.woff2") format("woff2"),
    url("/fonts/text-bold.woff") format("woff");
}

@font-face {
  font-family: "Title";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/fonts/title.woff2") format("woff2"),
    url("/fonts/title.woff") format("woff");
}

:root {
	--color: black;
	--background: white;
	--gold: #f9ead3;
	--gold-color: #111;

	--font: Title, sans-serif;
	--font-alt: 'Merriweather', serif;

	--p-side: 115px;
	--padding-side: var(--p-side);
	--p-top: 50px;
	--p-bottom: 35px;
	--p: 50px 115px 35px 115px;

	--nav-font-size: 30px;

	--content-w: 960px;
	--mobile-p: 20px;
	@media (min-width: 1195px) {
		--content-w: 1200px;
	}
	--hor-p: 100px; // Padding set on the sides
	--ver-p: 50px; // Padding set on top / bottom

	@media (max-width: 737px){
		--padding-side: var(--mobile-p);
		--nav-font-size:15;
	}
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}


	/* ----------------------------------------------------------------------------------------------------

		Super Form Reset

	A couple of things to watch out for:

			- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
				- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
					- You NEED to set the font-size and family on all form elements
		- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
	- You can style the upload button in webkit using ::-webkit-file-upload-button
	- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
		- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
		- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs

	----------------------------------------------------------------------------------------------------*/

	input,
	label,
	select,
	button,
	textarea
	{
		margin:0;
		border:0;
		padding:0;
		display:inline-block;
		vertical-align:middle;
		white-space:normal;
		background:none;
		line-height:1;

		/* Browsers have different default form fonts */
			font-size:13px;
		font-family:Arial;
	}

	/* Remove the stupid outer glow in Webkit */
		input:focus
	{
		outline:0;
	}

	/* Box Sizing Reset
	-----------------------------------------------*/

	/* All of our custom controls should be what we expect them to be */
		input,
	textarea
	{
		-webkit-box-sizing:content-box;
		-moz-box-sizing:content-box;
		box-sizing:content-box;
	}

	/* These elements are usually rendered a certain way by the browser */
		button,
	input[type=reset],
		input[type=button],
		input[type=submit],
		input[type=checkbox],
		input[type=radio],
		select
	{
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}

	/* Text Inputs
	-----------------------------------------------*/

	input[type=date],
		input[type=datetime],
		input[type=datetime-local],
		input[type=email],
		input[type=month],
		input[type=number],
		input[type=password],
		input[type=range],
		input[type=search],
		input[type=tel],
		input[type=text],
		input[type=time],
		input[type=url],
		input[type=week]
	{
	}

	/* Button Controls
	-----------------------------------------------*/

	input[type=checkbox],
		input[type=radio]
	{
		width:13px;
		height:13px;
	}

	/* File Uploads
	-----------------------------------------------*/

	input[type=file]
	{

	}

	/* Search Input
	-----------------------------------------------*/

	/* Make webkit render the search input like a normal text field */
		input[type=search]
	{
		-webkit-appearance:textfield;
		-webkit-box-sizing:content-box;
	}

	/* Turn off the recent search for webkit. It adds about 15px padding on the left */
			::-webkit-search-decoration
		{
			display:none;
		}

		/* Buttons
		-----------------------------------------------*/

		button,
		input[type="reset"],
			input[type="button"],
			input[type="submit"]
		{
			/* Fix IE7 display bug */
				overflow:visible;
			width:auto;
		}

		/* IE8 and FF freak out if this rule is within another selector */
				::-webkit-file-upload-button
			{	
				padding:0;
				border:0;
				background:none;
			}

			/* Textarea
			-----------------------------------------------*/

			textarea 
			{
				/* Move the label to the top */
					vertical-align:top;

				/* Turn off scroll bars in IE unless needed */
					overflow:auto;
			}

			/* Selects
			-----------------------------------------------*/

			select
			{

			}

			select[multiple] 
			{
				/* Move the label to the top */
					vertical-align:top;
			}


			body {
				font-family: var(--font-alt);
				background: var(--background);
				color: var(--color);
				overflow-x: hidden;
			}

			a {
				color: var(--color);
				nav & {
					margin-right: 2em;
				}
				nav &, 
				&.list-item {
					text-decoration: none;
					--transition-speed: 0.25s;
					position: relative;
					transition: var(--transition-speed);
					font-family: var(--font);
					&:before {
						content: "";
						height: 1px;
						max-width: 0;
						background: var(--color);
						transition: var(--transition-speed);
						position: absolute;
						left: 0;
						right: 0;
						bottom: -5px;
					}
					&:not(.logo):hover, &.active {
						&:before {
							transform: scaleY(1);
							max-width: 100%;
						}
					}
				}
			}
	}

	p, li {
		font-size: 0.9em;
		margin: 0 0 .5em 0;
		line-height: 1.5em;
	}

	hr { 
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		margin: 2em 0;
		adding: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: normal;
		font-family: var(--font);
	}
	h4 a, a h4, .cta {
		display: inline-block;
		background: var(--gold);
		color: var(--gold-color);
		padding: .85em;
		border-radius: 2px;
		margin-bottom: 1em;
		transition: all ease .5s;
		text-decoration: none;
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		border: 1px solid rgba(0,0,0,.1);
		margin-top: 1em;
		&:hover {
			background: var(--gold-color);
			color: var(--gold);
		}
	}
	`;

export default function GlobalStyleComponent({ children }) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
}
