import GlobalStyles from "components/GlobalStyle";
/* // To prevent TypeScript errors on the css prop on arbitrary elements, install @types/styled-components and add the following import once in your project: */
/* // cf: https://styled-components.com/docs/api */
/* import {} from 'styled-components/cssprop'; */
import { ApolloProvider } from "@apollo/client";
import getConfig from "next/config";
import { useApollo } from "lib/graphql/apolloClient";
import { GoogleAnalytics } from "nextjs-google-analytics";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
}

function MyApp({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps);
  return (
    <ApolloProvider client={apolloClient}>
      <GlobalStyles>
        <GoogleAnalytics trackPageViews />
        <Component {...pageProps} err={err} />
      </GlobalStyles>
    </ApolloProvider>
  );
}

export default MyApp;
